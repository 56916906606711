
<template>
  <div class="grid crud-demo">
    <div class="col-12">
      <div class="card">
        <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Neu" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Löschen" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedProdukt || !selectedProdukt.length" />
            </div>
          </template>

          <template v-slot:end>
            <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
          </template>
        </Toolbar>

        <DataTable ref="dt" :row-class="rowClass" :value="produkte" v-model:selection="selectedProdukt" dataKey="ID" :paginator="true" :rows="10" filter-display="menu" :filters="filters"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                   currentPageReportTemplate="{first} bis {last} von {totalRecords} Pflegebetten" responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Pflegebetten</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Suchen..." />
                            </span>
            </div>
          </template>

          <Column selectionMode="multiple" v-model:selection="selectedProdukt" headerStyle="width: 3rem"></Column>
          <Column field="ID" header="ID" :sortable="true" :filterMatchModeOptions="matchModes">
            <template #body="slotProps">
              <span class="p-column-title">ID</span>
              {{slotProps.data.ID}}
            </template>
            <template #filter>
              <InputText type="text" class="p-column-filter"  v-model="filters['ID'].value"/>
            </template>
            <template #filterclear></template>
            <template #filterapply></template>
          </Column>
          <Column field="TYP/MODEL" header="TYP/MODEL" :sortable="true" :filterMatchModeOptions="matchModes">
            <template #body="slotProps">
              <span class="p-column-title">TYP/MODEL</span>
              {{slotProps.data['TYP/MODEL']}}
            </template>
            <template #filter>
              <InputText type="text" class="p-column-filter"  v-model="filters['TYP/MODEL'].value"/>
            </template>
            <template #filterclear></template>
            <template #filterapply></template>
          </Column>
          <Column field="HERSTELLER" header="Hersteller" :sortable="true" :filterMatchModeOptions="matchModes">
            <template #body="slotProps">
              <span class="p-column-title">Hersteller</span>
              {{slotProps.data.HERSTELLER}}
            </template>
            <template #filter>
              <InputText type="text" class="p-column-filter"  v-model="filters['HERSTELLER'].value"/>
            </template>
            <template #filterclear></template>
            <template #filterapply></template>
          </Column>
          <Column field="ETAGE" header="Etage" :sortable="true" :filterMatchModeOptions="matchModes">
            <template #body="slotProps">
              <span class="p-column-title">Etage</span>
              {{slotProps.data.ETAGE}}
            </template>
            <template #filter>
              <InputText type="text" class="p-column-filter"  v-model="filters['ETAGE'].value"/>
            </template>
            <template #filterclear></template>
            <template #filterapply></template>
          </Column>
          <Column field="ZIMMER" header="Zimmer" :sortable="true" :filterMatchModeOptions="matchModes">
            <template #body="slotProps">
              <span class="p-column-title">Zimmer</span>
              {{slotProps.data.ZIMMER_NR}}
            </template>
            <template #filter>
              <InputText type="text" class="p-column-filter"  v-model="filters['ZIMMER'].value"/>
            </template>
            <template #filterclear></template>
            <template #filterapply></template>
          </Column>
          <Column field="STANDORT" header="Standort" :sortable="true" :filterMatchModeOptions="matchModes">
            <template #body="slotProps">
              <span class="p-column-title">Standort</span>
              {{slotProps.data.STANDORT}}
            </template>
            <template #filter>
              <InputText type="text" class="p-column-filter"  v-model="filters['STANDORT'].value"/>
            </template>
            <template #filterclear></template>
            <template #filterapply></template>
          </Column>
          <Column field="STATUS" header="Status" :sortable="true" :filterMatchModeOptions="matchModes">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              <div :class="statusClass(slotProps.data)">
                {{slotProps.data.STATUS}}
              </div>
            </template>
            <template #filter>
              <InputText type="text" class="p-column-filter"  v-model="filters['STATUS'].value"/>
            </template>
            <template #filterclear></template>
            <template #filterapply></template>
          </Column>
          <Column field="DASHBOARD" header="Dashboard">
            <template #body="slotProps">
              <span class="p-column-title">Dashboard</span>
              <Button icon="pi pi-link"  @click="onRowSelect(slotProps.data)" />
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProdukt(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteProdukt(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <Dialog v-model:visible="produktDialog" :style="{width: '450px'}" header="Pflegebett Details" :modal="true" class="p-fluid">
          <div v-if="hide" class="field">
            <label for="FK_ID_AUFTRAGGEBER" class="mb-3">Auftraggeber ID</label>
            <AutoComplete id="FK_ID_AUFTRAGGEBER" v-model="produktObj.FK_ID_AUFTRAGGEBER" :dropdown="true" :suggestions="filteredAuftraggeber" @complete="searchAuftraggeber($event)" placeholder="Unternehmen" />
          </div>
          <div class="field">
            <label for="standorte">Standort</label>
            <Dropdown id="standorte" v-model="produktObj.STANDORT" optionLabel="BEZEICHNUNG"  :options="standorte" :filter="true" placeholder="Standort">
              <template #value="slotProps">
                <div v-if="neu && slotProps.value">
                  {{slotProps.value.BEZEICHNUNG}}
                </div>
                <div v-else-if="slotProps.value && !neu">
                  {{slotProps.value}}
                </div>
                <div v-else>
                  {{slotProps.placeholder}}
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="field">
            <label for="TYP/MODEL">Typ/Model</label>
            <InputText id="typ/model" v-model.trim="produktObj['TYP/MODEL']" required="true" autofocus :class="{'p-invalid': submitted && !produktObj['TYP/MODEL']}" />
            <small class="p-invalid" v-if="submitted && !produktObj['TYP/MODEL']">Typ/Model muss angegeben werden.</small>
          </div>
          <div class="field">
            <label for="HERSTELLER">Hersteller</label>
            <InputText id="hersteller" v-model.trim="produktObj.HERSTELLER" required="true" autofocus :class="{'p-invalid': submitted && !produktObj.HERSTELLER}" />
            <small class="p-invalid" v-if="submitted && !produktObj.HERSTELLER">Hersteller muss angegeben werden.</small>
          </div>
          <div class="field">
            <label for="SERIENNUMMER">Seriennummer</label>
            <InputText id="seriennummer" v-model.trim="produktObj.SERIENNUMMER" required="false" autofocus/>
          </div>
          <div class="field">
            <label for="INVENTARNUMMER">Inventarnummer</label>
            <InputText id="inventarnummer" v-model.trim="produktObj.INVENTARNUMMER" required="false" autofocus/>
          </div>
          <div class="field">
            <label for="SCHUTZKLASSE">Schutzklasse</label>
            <InputText id="schutzklasse" v-model.trim="produktObj.SCHUTZKLASSE" required="false" autofocus/>
          </div>
          <div class="field">
            <label for="BAUJAHR">Baujahr</label>
            <InputText id="baujahr" v-model.trim="produktObj.BAUJAHR" required="false" autofocus/>
          </div>
          <div class="field">
            <label for="ABLAUFJAHR">Ablaufjahr</label>
            <InputText id="ablaufjahr" v-model.trim="produktObj.ABLAUFJAHR" required="false" autofocus/>
          </div>
          <div class="field">
            <label for="KENNZEICHNUNG">Kennzeichnung</label>
            <InputText id="kennzeichnung" v-model.trim="produktObj.KENNZEICHNUNG" required="false" autofocus/>
          </div>
          <div class="field">
            <label for="ETAGE">Etage</label>
            <InputText id="etage" v-model.trim="produktObj.ETAGE" required="true" autofocus :class="{'p-invalid': submitted && !produktObj.ETAGE}" />
            <small class="p-invalid" v-if="submitted && !produktObj.ETAGE">Etage muss angegeben werden.</small>
          </div>
          <div class="field">
            <label for="ZIMMER">Zimmer Nr.</label>
            <InputText id="zimmer" v-model.trim="produktObj.ZIMMER_NR" required="true" autofocus :class="{'p-invalid': submitted && !produktObj.ZIMMER_NR}" />
            <small class="p-invalid" v-if="submitted && !produktObj.ZIMMER_NR">Zimmer muss angegeben werden.</small>
          </div>
          <div class="field">
            <div class="formgrid grid">
              <div class="field-checkbox col-12">
                <Checkbox id="validiert" name="VALIDIERT" value="true" v-model="produktObj.VALIDIERT" :binary="true" />
                <label for="VALIDIERT">Validiert</label>
              </div>
            </div>
          </div>
          <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveProdukte" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteProduktDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="produktObj"><b>{{produktObj.GRUPPE}}</b> wirklich löschen?</span>
          </div>
          <template #footer>
            <Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteProduktDialog = false"/>
            <Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteProdukt" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteProdukteDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="produktObj">Ausgewählte Produkte wirklich löschen?</span>
          </div>
          <template #footer>
            <Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteProdukteDialog = false"/>
            <Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProdukte" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import DataService from "@/service/DataService";
import store from "@/store";

export default {
  data() {
    return {
      produkte: null,
      produktDialog: false,
      deleteProduktDialog: false,
      deleteProdukteDialog: false,
      neu: false,
      produktObj: {},
      selectedProdukt: null,
      selectedProdukt2: null,
      filters: {},
      submitted: false,
      standorte: null,
      filteredGruppen: null,
      filteredAuftraggeber: null,
      auftraggeberCustom: [],
      auftraggeber: null,
      hide: false,
      matchModes: [
        {label: 'Suchbegriff', value: FilterMatchMode.CONTAINS},
      ]
    }
  },
  dataService: null,
  created() {
    this.dataService = new DataService();
    this.initFilters();
  },
  async mounted() {
    this.standorte = await this.dataService.getStandorte();
    this.auftraggeber = await this.dataService.getAuftraggeberAll();

    this.auftraggeber.forEach((el) => {
      let string = `${el.ID} ${el.NAME}`;
      this.auftraggeberCustom.push(string);
    })

    this.produkte = await this.dataService.getProdukte('Pflegebett', false);

    if(store.state.token_decoded.unternehmen === 'Acticom AG'){
      this.hide = true;
    }
  },
  methods: {
    openNew() {
      this.produktObj = {};
      this.submitted = false;
      this.produktDialog = true;
      this.neu = true;
    },
    hideDialog() {
      this.produktDialog = false;
      this.submitted = false;
    },
    async saveProdukte(){
      this.submitted = true;

      if(!this.hide){
        this.produktObj.FK_ID_AUFTRAGGEBER = store.state.token_decoded.id_unternehmen;
      }
      if(this.neu){
        this.produktObj.FK_ID_AUFTRAGNEHMER = 1;
        this.produktObj.GRUPPE = 'Pflegebett';
        await this.dataService.saveProdukte(this.produktObj);
        this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Produkt erstellt', life: 3000});
        setTimeout(async()=> {this.produkte = await this.dataService.getProdukte('Pflegebett', false);}, 100);
        this.produktDialog = false;
        this.produktObj = {};

      } else {
        this.produktObj.FK_ID_AUFTRAGNEHMER = 1;
        this.produktObj.GRUPPE = 'Pflegebett';
        await this.dataService.updateProdukte(this.produktObj);
        this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Produkt aktualisiert', life: 3000});
        setTimeout(async()=> { this.produkte = await this.dataService.getProdukte('Pflegebett', false);}, 100);
        this.produktDialog = false;
        this.produktObj = {};
      }

    },
    editProdukt(produkt) {
      this.produktObj = {...produkt};
      this.produktDialog = true;
      this.neu = false;
    },
    confirmDeleteProdukt(produkt) {
      this.produktObj = produkt;
      this.deleteProduktDialog = true;
    },
    async deleteProdukt() {
      let data = [];
      data.push(this.produktObj);
      //await this.dataService.deleteProdukte(data);
      await this.dataService.archiveProdukte(data);

      this.deleteProduktDialog = false;
      this.produktObj = {};
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Produkt gelöscht', life: 3000});
      setTimeout(async()=> { this.produkte = await this.dataService.getProdukte('Pflegebett', false);}, 100);

    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProdukteDialog = true;
    },
    async deleteSelectedProdukte() {
      this.deleteProdukteDialog = false;
      //await this.dataService.deleteProdukte(this.selectedProdukt);
      await this.dataService.archiveProdukte(this.selectedProdukt);

      this.selectedProdukt = null;
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Produkte gelöscht', life: 3000});
      setTimeout(async()=> { this.produkte = await this.dataService.getProdukte('Pflegebett', false);}, 100);

    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'ID': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'TYP/MODEL': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'HERSTELLER': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'ETAGE': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'ZIMMER': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'STANDORT': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'STATUS': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    },
    onRowSelect(event){
      // console.log(event.ID)
      this.$router.push(`/produktdashboard/${event.ID}`);
    },
    statusClass(data){
      return [
        {
          'inOrdnung': data.STATUS === 'i.O',
          'nichtInOrdnung': data.STATUS === 'n.i.O'
        }
      ];
    },
    rowClass(data) {
      return data.STATUS === 'n.i.O' ? 'row-accessories': null;
    },
    searchProduktGruppe(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredGruppen = [...this.gruppen];
        }
        else {
          this.filteredGruppen = this.gruppen.filter((gruppe) => {
            return gruppe.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 50);
    },
    searchAuftraggeber(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredAuftraggeber = [...this.auftraggeberCustom];
        }
        else {
          this.filteredAuftraggeber = this.auftraggeberCustom.filter((auftraggeber) => {
            return auftraggeber.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 50);
    },
  }
}
</script>

<style scoped lang="scss">
@import '../assets/demo/styles/badges.scss';

.nichtInOrdnung {
  font-weight: 700;
  color: #FF5252;
}

.inOrdnung {
  font-weight: 700;
  color: #689F38;
}

::v-deep(.row-accessories) {
  background-color: rgba(0,0,0,.15) !important;
}

</style>
